var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button pd grid-x align-items-center" }, [
    _c("div", { staticClass: "cell auto" }),
    _vm._v(" "),
    !_vm.isAdded
      ? _c(
          "button",
          {
            staticClass: "btn add align-items-center",
            on: { click: _vm.addTopic },
          },
          [_vm._v("\n    AGREGAR A MIS TEMAS\n    "), _vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isAdded
      ? _c(
          "a",
          {
            staticClass: "btn",
            attrs: {
              target: "_blank",
              rel: "noopener",
              href: "https://mas.larepublica.co/noticias/editar-top-20",
            },
          },
          [_vm._v("TEMA AGREGADO")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "icon-add" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }