import axios from "axios";
import Vue from "vue";
import { ToastPlugin } from "bootstrap-vue";
import store from "../../../../lr/js/vue/store";
import { mapActions, mapGetters } from "vuex";

Vue.use(ToastPlugin);

export const postMixin = {
  store,
  created: function () {
    let last_known_scroll_position = 0;
    let ticking = false;
    const _self = this;
    window.addEventListener("scroll", function (e) {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
        setTimeout(() => {
          _self.scrolled(last_known_scroll_position);
          ticking = false;
        }, 500);
      }
      ticking = true;
    });
    this.postInfo = postInfo;
  },
  mounted: function () {
    if (window.eData.hasWidgets) {
      iFrameResize(null, 'iframe[src*="widgets"]');
    }
    this.loadTopics();
  },
  methods: {
    ...mapActions("auth", {
      addTopicToUser: "addTopic",
      login: "login",
      getTopics: "getUserTopics",
      getFavorites: "getUserFavorites",
    }),
    loadTopics: function () {
      const _self = this;
      setTimeout(() => {
        if (!_self.hasLoaded) {
          return _self.loadTopics();
        } else {
          _self.getTopics();
          _self.getFavorites();
        }
      }, 500);
    },
    scrolled: function (scrollY) {
      this.isContentFixed = scrollY > 700 && window.innerHeight > 640;
    },
    toggleTopicMenu: function () {
      this.openTopicsMenu = !this.openTopicsMenu;
    },
    showMessage: function (title, message, actions) {
      const _self = this;
      const h = this.$createElement;
      let elMsg = [h("p", message)];
      // Create the message
      if (actions && actions.length) {
        elMsg = elMsg.concat(
          h(
            "div",
            {
              class: ["text-right", "mb-0"],
            },
            actions.map((q) => {
              if (q.modal) {
                return h(
                  "a",
                  {
                    class: q.class,
                    on: {
                      click: function () {
                        _self.$bvToast.hide();
                        _self.openSubscribeModal = true;
                      },
                    },
                  },
                  q.title
                );
              } else if (q.href) {
                return h(
                  "a",
                  {
                    class: q.class,
                    attrs: {
                      href: q.href,
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  q.title
                );
              } else if (q.action) {
                switch (q.action) {
                  case "login":
                    return h(
                      "a",
                      {
                        class: q.class,
                        on: {
                          click: function () {
                            _self.$bvToast.hide();
                            _self.login();
                          },
                        },
                      },
                      q.title
                    );
                    break;
                  default:
                    break;
                }
              }
            })
          )
        );
      }
      const vNodesMsg = h(
        "div",
        {
          class: ["mb-0"],
        },
        elMsg
      );
      this.$bvToast.toast([vNodesMsg], {
        title: title,
        toaster: "b-toaster-bottom-left",
        autoHideDelay: 10000,
        appendToast: true,
      });
    },
    addTopic: function (id, topic, idx) {
      if (this.user) {
        if (!this.topics[idx].added) {
          const _self = this;
          const axiosConfig = {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          };
          axios
            .post(
              "/api/lrplus/topics",
              {
                id: id,
                name: topic,
              },
              axiosConfig
            )
            .then(function (d) {
              if (d.data.success) {
                _self.addTopicToUser({
                  topicId: id,
                  topicName: topic,
                });
                _self.showMessage(
                  "Agregar Tema",
                  `${topic} ha sido agregado satisfactoriamente.`
                );
              } else {
                switch (d.data.errorCode) {
                  case "limit":
                    if (_self.user.isSubscriber) {
                      _self.showMessage(
                        "Agregar Tema",
                        "Ha superado el límite de temas",
                        [
                          {
                            title: "Administrar Temas",
                            href: "https://mas.larepublica.co/noticias/editar-top-20",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    } else {
                      _self.showMessage(
                        "Agregar Tema",
                        "Ha superado el límite de temas, suscribase para poder agregar más.",
                        [
                          {
                            title: "Administrar Temas",
                            href: "https://mas.larepublica.co/noticias/editar-top-20",
                            class: ["btn"],
                          },
                          {
                            title: "Suscribirse",
                            modal: "subscribe",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    }
                    break;
                  case "auth":
                    _self.showMessage(
                      "Agregar Tema",
                      "Debe ingresar para agregar el tema.",
                      [
                        {
                          title: "Ingresar",
                          class: ["btn", "lr"],
                          action: "login",
                        },
                      ]
                    );
                    break;
                  default:
                    _self.showMessage(
                      "Agregar Tema",
                      "Ha ocurrido un error, intente de nuevo más tarde."
                    );
                    break;
                }
              }
            })
            .catch(function (error) {
              _self.showMessage(
                "Agregar Tema",
                "Ha ocurrido un error, intente de nuevo más tarde."
              );
            });
        }
      } else {
        this.showMessage(
          "Agregar Tema",
          "Debe ingresar para agregar el tema.",
          [
            {
              title: "Ingresar",
              class: ["btn", "lr"],
              action: "login",
            },
          ]
        );
      }
    },
    subscribeSuccessed: function () {
      this.openSubscribeModal = false;
      this.showMessage(
        "Suscripción",
        "Ha activado satisfactoriamente la suscripción"
      );
    },
    closeAuthor: function () {
      this.author = null;
    },
    showAuthor: function (id) {
      this.author = id;
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      userTopics: "topics",
      token: "token",
      hasLoaded: "hasLoaded",
    }),
    topics() {
      const _self = this;
      return this.postInfo.topicIds
        .map((q) => {
          return {
            id: q,
          };
        })
        .map((q) => {
          return {
            id: q.id,
            added: _self.userTopics
              ? _self.userTopics.some((p) => p.topicId == q.id)
              : false,
            loading: !!q.loading,
          };
        });
    },
  },
  data: function () {
    return {
      openSubscribeModal: false,
      openTopicsMenu: false,
      isContentFixed: window.scrollY > 700,
      author: null,
    };
  },
};

export const postHeaderMixin = {
  created() {
    this.userBasic();
  },
  methods: {
    toggleShare: function () {
      this.shareIsVisible = !this.shareIsVisible;
    },
    hideShare: function () {
      this.shareIsVisible = false;
    },
  },
  data: function () {
    return {
      shareIsVisible: false,
    };
  },
};

export const topicMixin = {
  store,
  mounted: function () {
    this.tagInfo = tagInfo;
    this.loadTopics();
  },
  methods: {
    ...mapActions("auth", {
      addTopicToUser: "addTopic",
      login: "login",
      getTopics: "getUserTopics",
    }),
    loadTopics: function () {
      const _self = this;
      setTimeout(() => {
        if (!_self.hasLoaded) {
          return _self.loadTopics();
        } else {
          return _self.getTopics();
        }
      }, 500);
    },
    showMessage: function (title, message, actions) {
      const _self = this;
      const h = this.$createElement;
      let elMsg = [h("p", message)];
      // Create the message
      if (actions && actions.length) {
        elMsg = elMsg.concat(
          h(
            "div",
            {
              class: ["text-right", "mb-0"],
            },
            actions.map((q) => {
              if (q.modal) {
                return h(
                  "a",
                  {
                    class: q.class,
                    on: {
                      click: function () {
                        _self.$bvToast.hide();
                        _self.openSubscribeModal = true;
                      },
                    },
                  },
                  q.title
                );
              } else if (q.href) {
                return h(
                  "a",
                  {
                    class: q.class,
                    attrs: {
                      href: q.href,
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  q.title
                );
              } else if (q.action) {
                switch (q.action) {
                  case "login":
                    return h(
                      "a",
                      {
                        class: q.class,
                        on: {
                          click: function () {
                            _self.$bvToast.hide();
                            _self.login();
                          },
                        },
                      },
                      q.title
                    );
                    break;
                  default:
                    break;
                }
              }
            })
          )
        );
      }
      const vNodesMsg = h(
        "div",
        {
          class: ["mb-0"],
        },
        elMsg
      );
      this.$bvToast.toast([vNodesMsg], {
        title: title,
        toaster: "b-toaster-bottom-left",
        autoHideDelay: 10000,
        appendToast: true,
      });
    },
    addTopic: function () {
      if (this.user) {
        if (!this.loading) {
          const _self = this;
          this.loading = true;
          const axiosConfig = {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          };
          axios
            .post(
              "/api/lrplus/topics",
              {
                id: _self.tagInfo.id,
                name: _self.tagInfo.name,
              },
              axiosConfig
            )
            .then(function (d) {
              if (d.data.success) {
                _self.loading = false;
                _self.addTopicToUser({
                  topicId: _self.tagInfo.id,
                  topicName: _self.tagInfo.name,
                });
                _self.showMessage(
                  "Agregar Tema",
                  `${_self.tagInfo.name} ha sido agregado satisfactoriamente.`
                );
              } else {
                _self.loading = false;
                switch (d.data.errorCode) {
                  case "limit":
                    if (_self.user.isSubscriber) {
                      _self.showMessage(
                        "Agregar Tema",
                        "Ha superado el límite de temas",
                        [
                          {
                            title: "Administrar Temas",
                            href: "https://mas.larepublica.co/noticias/editar-top-20",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    } else {
                      _self.showMessage(
                        "Agregar Tema",
                        "Ha superado el límite de temas, suscribase para poder agregar más.",
                        [
                          {
                            title: "Administrar Temas",
                            href: "https://mas.larepublica.co/noticias/editar-top-20",
                            class: ["btn"],
                          },
                          {
                            title: "Suscribirse",
                            modal: "subscribe",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    }
                    break;
                  case "auth":
                    _self.showMessage(
                      "Agregar Tema",
                      "Debe ingresar para agregar el tema.",
                      [
                        {
                          title: "Ingresar",
                          class: ["btn", "lr"],
                          action: "login",
                        },
                      ]
                    );
                    break;
                  default:
                    _self.showMessage(
                      "Agregar Tema",
                      "Ha ocurrido un error, intente de nuevo más tarde."
                    );
                    break;
                }
              }
            })
            .catch(function (error) {
              _self.loading = false;
              _self.showMessage(
                "Agregar Tema",
                "Ha ocurrido un error, intente de nuevo más tarde."
              );
            });
        }
      } else {
        this.showMessage(
          "Agregar Tema",
          "Debe ingresar para agregar el tema.",
          [
            {
              title: "Ingresar",
              class: ["btn", "lr"],
              action: "login",
            },
          ]
        );
      }
    },
    subscribeSuccessed: function () {
      this.openSubscribeModal = false;
      this.showMessage(
        "Suscripción",
        "Ha activado satisfactoriamente la suscripción"
      );
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      userTopics: "topics",
      token: "token",
      hasLoaded: "hasLoaded",
    }),
  },
  data: function () {
    return {
      openSubscribeModal: false,
    };
  },
};
