import {
    initMain
} from './vue/controllers/main';
import {
    header
} from './vue/controllers/header';

import lazyload from '../../lr/js/features/lazy-load';
import {
    topicMixin
} from './vue/mixins/lrplus';

import AddTopic from './vue/components/add-topic/AddTopic.vue';

lazyload();

const mixins = [topicMixin];

initMain(mixins, {
    SectionPager: () => import('./vue/components/section-pager/SectionPager.vue'),
    SubscribeModal: () => import('./vue/components/subscribe-modal/SubscribeModal.vue'),
    AddTopic,
    Trends: () => import('./vue/components/trends/Trends.vue')
});
header();